import React from 'react';
//import logo from './logo.svg';

import { useTranslation } from 'react-i18next';

import '@fortawesome/fontawesome-free/css/all.css';
import './styles/main.css';

import Header from './components/Header'
import MainTitle from './components/MainTitle'
import SubTitle from './components/SubTitle'
import MainText from './components/MainText'
import List from './components/List'
import BlockCenter from './components/BlockCenter'
import BlockContatti from './components/BlockContatti'
import Footer from './components/Footer'

import image from './img/image.png'

function App() {

  const { t } = useTranslation();

  const mainList = [
    t('mainList.item1'), t('mainList.item2'), t('mainList.item3'), t('mainList.item4'), t('mainList.item5'), 
    t('mainList.item6'), t('mainList.item7'), t('mainList.item8'), t('mainList.item9'), t('mainList.item10')
  ]

  const secondList = [t('secondList.item1'), t('secondList.item2'), t('secondList.item3'), t('secondList.item4'), t('secondList.item5'), t('secondList.item6')]

  const list = ( mainList.map(item => (<List item={item} />)) )

  const listTwo = ( secondList.map(item => (<List item={item} />)) )

  const firstBlock = (
    <div className="container mx-auto grid grid-cols-2 pt-32 px-8 sm:px-0 md:px-0 lg:px-0 xl:px-0">
      <div className="col-span-2 md:col-span-1">
        <MainTitle item="CRM.WEB" />
        <SubTitle item={ t('subtitle') } />
        <MainText item={ t('mainText') }/>
        <div className="mt-4 p-0">
          { list }
        </div>
        <MainText item={ t('secondText') }/>
        <div className="mt-4 p-0">
          { listTwo }
        </div>
      </div>
      <div className="col-span-2 md:col-span-1 mt-16">
        <img src={image} alt="immagine"/>
      </div>
    </div>
  )

  const secondBlock = (
    <div className="w-full arancio mt-16 mb-16">
      <div className="container mx-auto pt-8 pb-8">
          <BlockCenter title={ t('contenuti.title') }
                        subtitle={ t('contenuti.subtitle') }
                        text={ t('contenuti.text') } />
      </div>
    </div>
  )

  return (
    <div className="App">
      <Header />
      { firstBlock }
      { secondBlock }

      <div className="container mx-auto mb-16">
        <BlockContatti title={ t('contact') }
                        text={ t('contactText') }
                        formText={ t('formText') }
                        send={ t('sendButton') } />
      </div>
      <Footer/>
    </div>
  );
}

export default App;
