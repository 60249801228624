import React from 'react'
import logo from '../img/logo-glocal.png'

const Footer = () => {

    return (
        <div>

            <div className="w-full arancio">
                <div className="container mx-auto grid grid-cols-12">
                    <div className="col-span-5"></div>
                    <div className="col-span-2 py-8">
                        <img src={logo} alt="logo footer" className="logo-footer" />
                    </div>
                    <div className="col-span-5"></div>
                </div>
            </div>

            <div className="w-full grey py-2"></div>

            <div className="w-full arancio">
                <div className="container mx-auto">
                    <p className="text-xs text-white py-4 text-center">
                        © All Right Reserved to GLOCAL s.r.l. - C.F. e P.IVA: 03971490168 - Via Roma 133/D-G 24064 Grumello del Monte (BG) - glocalnow@gmail.com
                    </p>
                </div>
            </div>

        </div>
        
    )

}

export default Footer;