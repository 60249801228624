import React from 'react'

const List = ({ item }) => {

    return (
        <p className="text-base textGrey font-bold">{ item }</p>
    )

}

export default List;