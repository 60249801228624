import React from 'react'

const MainText =  ({ item }) => {

    return (
        <p className="text-base mt-8 textGrey"
            dangerouslySetInnerHTML={{__html: item }}></p>
    )

}


export default MainText;