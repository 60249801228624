import React from 'react'
import device from '../img/device.png'

const BlockCenter = ({title, subtitle, text}) => {

    return (
        <div className="grid grid-cols-3 gap-4 px-6 md:px-0">
            <div className="col-span-3">
                <p className="text-center text-white text-4xl uppercase">{ title }</p>
            </div>
            <div className="col-span-3 md:col-span-1">
                <p className="text-center text-white text-2xl uppercase">{ subtitle }</p>
                <p className="text-white text-sm" dangerouslySetInnerHTML={{__html: text }}></p>
            </div>
            <div className="col-span-3 md:col-span-2">
                <img src={device} alt="device"/>
            </div>
        </div>
    )
}

export default BlockCenter;