import React, {useState} from 'react'


const BlockContatti = ({title, text, formText, send}) => {
    
    const [email, setEmail] = useState();

    const inputHandler = (event) => {
        setEmail(event.target.value)
    }

    const mailgun = require("mailgun-js");
    const DOMAIN = "sandboxdc443ad16ed84473bfe08833c4332107.mailgun.org";
    const sendEmail = () => {
        if (email) {
            const mg = mailgun({apiKey: "c71198e6816d72cad88fb76bd77256b8-cb3791c4-d0640921", domain: DOMAIN});
            const data = {
                from: "Mailgun Sandbox <postmaster@sandboxdc443ad16ed84473bfe08833c4332107.mailgun.org>",
                to: "pierangelo1982@gmail.com",
                subject: "Email Inviata da CRM Glocal",
                text: email
            };
            mg.messages().send(data, function (error, body) {
                console.log(body);
                alert("Email inviata con successo, ti contatteremo al più presto!");
            });
        } else {
            alert("Email non iserità");
        }
    }

    
    return (
        <div className="grid grid-cols-2 gap-4 px-6 sm:px-0">
            <div className="col-span-2 md:col-span-1">
                <p className="text-left textGrey text-4xl font-bold uppercase">{ title }</p>
                <p className="text-left textGrey text-base mt-8">{ text }</p>
            </div>
            <div className="col-span-2 md:col-span-1 grid grid-cols-4">
                <div className="col-span-4 md:col-span-3 grey m-8 p-8 pr-16 rounded">
                    <p className="textGrey text-base text-left mb-8">{ formText }</p>
                    <input type="text"
                            name={email}
                            value={email}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            placeholder="email"
                            onChange={inputHandler} />
                </div>
                <div className="col-span-4 md:col-span-1">
                    <button className="verde text-white uppercase text-3xl rounded-full px-12 py-16 right contact-btn" onClick={sendEmail}>{ send }</button>
                </div>
            </div>
        </div>
    )
        

}

export default BlockContatti;